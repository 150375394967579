import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { setTimeout } from 'timers';
import { exchangeMSOauthCodeForLogin, storeLoginPayload } from 'utils/ez_api/loginUtil';
import { Mixpanel } from 'utils/mixPanelUtil';
import { showMessage, showNotification } from 'utils/notificationUtil';

export const MicrosoftLoginCallback: React.FC = () => {
    const location = useLocation(); // Detects changes in the route
    const history = useHistory(); // To programmatically navigate

    useEffect(() => {
        // get the code from the url
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const state = params.get('state');

        if (code && state) {
            exchangeMSOauthCodeForLogin(code, state)
                .then(({ data }) => {
                    storeLoginPayload(data.jwt, data.loginPayload);

                    try {
                        Mixpanel.setLoggedUserData(data.loginPayload);
                    } catch (error) {}

                    showNotification('success', 'Login successful. Redirecting...');

                    setImmediate(() => {
                        history.push('/home');
                    });
                })
                .catch(err => {
                    showMessage('error', 'User not found. Please use a correct email address.');
                    history.push('/');
                });
        }
    }, []);

    return (
        <>
            <style>{keyframes}</style>
            <div style={styles.app}>
                <div style={styles.loaderContainer}>
                    <div style={styles.loader}>
                        <span>Exchange in Progress</span>
                        <div>
                            <span style={{ ...styles.dot, ...styles.dot1 }} />
                            <span style={{ ...styles.dot, ...styles.dot2 }} />
                            <span style={{ ...styles.dot, ...styles.dot3 }} />
                            <span style={{ ...styles.dot, ...styles.dot4 }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const keyframes = `
    @keyframes tint {
        50% {
            background-color: transparent;
            transform: scale(0.8);
        }
    }
`;

const styles: Record<string, React.CSSProperties> = {
    app: {
        backgroundSize: 'cover',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
    },
    loaderContainer: {
        margin: '3rem',
        height: '300px',
    },
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        width: '100vw',
        height: '100vh',
        color: '#4f5156', // sdk-dark
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '2rem 0',
    },
    dot: {
        width: '40px',
        height: '8px',
        backgroundColor: 'transparent',
        display: 'inline-block',
        marginRight: '2px',
        animation: 'tint 1600ms infinite',
    },
    dot1: {
        animationDelay: '0ms',
        backgroundColor: '#f58d50', // sdk-orange
    },
    dot2: {
        animationDelay: '200ms',
        backgroundColor: '#bcd64a', // sdk-green
    },
    dot3: {
        animationDelay: '400ms',
        backgroundColor: '#2a728e', // sdk-teal
    },
    dot4: {
        animationDelay: '600ms',
        backgroundColor: '#fbcc33', // sdk-yellow
    },
};
