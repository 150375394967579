// Core React libraries import
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

// Core ANTD libraries import
import { Alert, Row, Col } from 'antd';

// EZ web-app utils import
import { getNotification, loginInterface, portToString } from '../utils/ez_api/loginUtil';
// EZ web-app components import
import { IResolveParams } from '../components/social';
import { LogoImg } from '../components/social/LogoImg';
// EZ web-app pages import
import { RegisterUser } from './subpages/RegisterUser';
import { RegisterByEmail } from './auth/registerByEmail';
// EZ web-app styles import
import '../styles/login.scss';
import { loadSubscriptionListFromServer } from 'utils/paymentUtil';

export interface LoginProps {
    handleResolve: any;
    handleReject: any;
    userLoggedIn: any;
    showNotification?: any;
}

export const Register = React.memo(function Login(props: any) {
    // const [isLoading, setIsLoading] = React.useState(false);
    const history = useHistory();
    const [notificationSuccess, setNotificationSuccess] = useState<string | null>(null);

    useEffect(() => {
        const isNotification = getNotification();
        setNotificationSuccess(isNotification);
        setTimeout(() => setNotificationSuccess(null), 3000);
        if (localStorage.getItem('jwt')) history.push('/home');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadSubscriptionListFromServer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row justify="center">
            <Col
                xs={{ offset: 0, span: 16 }}
                sm={{ offset: 0, span: 16 }}
                md={{ offset: 0, span: 12 }}
                lg={{ offset: 0, span: 12 }}
                xl={{ offset: 0, span: 10 }}
            >
                <div className="login-wrapper">
                    <div className="success-toaster">
                        {notificationSuccess ? (
                            <Alert message={notificationSuccess} type="success" showIcon />
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="login-page">
                        <LogoImg />
                        <div className="login-register-wrapper pt-5">
                            {/* <RegisterUser
                                handleResolve={handleLoginResolve}
                                handleReject={handleReject}
                                userLoggedIn={handleSignUp}
                                showNotification={showNotification}
                            /> */}
                            <div className="mr-4 ml-4">
                                <div className="login-title"> Register new account </div>
                                <RegisterByEmail />
                            </div>
                            <div className="new-registration mt-4">
                                <span>
                                    Already have an account?&nbsp;
                                    {/* <span className="login-link" onClick={showLogin}>
                                        Login
                                    </span> */}
                                    <Link to="/login">
                                        <span className="">Login</span>
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
});
