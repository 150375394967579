/**
 *
 * LoginSocialMicrosoft
 *
 */
import React, { memo } from 'react';
import { getMSOauthLoginURL, InterfacePort } from '../../utils/ez_api/loginUtil';
import { IResolveParams, objectType } from './';

import { showMessage } from 'utils/notificationUtil';

interface Props {
    className?: string;
    children?: React.ReactNode;
    onReject: (reject: string | objectType) => void;
    onResolve: ({ provider, data }: IResolveParams) => void;
}

export const LoginSocialMicrosoft = memo((props: Props) => {
    const { className, children, onReject, onResolve } = props;

    const handleLogin = () => {
        // Get the login URL from the server
        getMSOauthLoginURL()
            .then(resp => {
                const url = resp.data?.url;
                window.open(url, '_self');
            })
            .catch(() => {
                showMessage('error', 'Failed to get login URL');
            });
    };

    return (
        <div className={className} onClick={handleLogin}>
            {children}
        </div>
    );
});

export default LoginSocialMicrosoft;
