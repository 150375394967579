// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Input,
    Form,
    FormInstance,
    Col,
    Select,
    Badge,
    Checkbox,
    Divider,
    Typography,
} from 'antd';
import moment from 'moment';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
import { TransactionTaskItemType, TransactionTaskListType } from 'types/transaction';
import { EZDatePicker } from 'components';
import { BellFilled } from '@ant-design/icons';
import { getTaskDetail, getTransactionTaskLists } from 'utils/ez_api/taskAPIs';
import { AccountCompactDataType } from 'types/account';
import { getAllTeamMembers } from 'utils/ez_api/memberAPIs';
// EZ web-app components import

// EZ web-app styles and assets import

const NO_OF_DAYS_REMINDER: number[] = Array.from(Array(7).keys());

export const EditTaskModalContent: React.FC<{
    contractAddress?: string;
    contractID: number;
    task: Partial<TransactionTaskItemType>;
    form: FormInstance<any>;
    taskListMenu?: TransactionTaskListType[];
    subUsers: AccountCompactDataType[];
    mode: 'EDIT' | 'ADD';
}> = ({ task, form, contractID, taskListMenu, mode, subUsers, contractAddress }) => {
    const [taskListMenuInModal, setTaskListMenuInModal] = useState<TransactionTaskListType[]>(
        taskListMenu || []
    );

    const _getDetail = (taskID: number) => {
        getTaskDetail(contractID, taskID).then(resp => {
            const data = resp.data as TransactionTaskItemType;

            if (data.task_todo_reminders?.length) {
                const arrBeforeDueDateValues: string[] = [],
                    arrAfterDueDateValues: string[] = [];
                for (let index = 0; index < data.task_todo_reminders.length; index++) {
                    const reminder = data.task_todo_reminders[index];

                    if (reminder.day_after_before === '1') {
                        arrBeforeDueDateValues.push(reminder.day_number);
                    } else if (reminder.day_after_before === '2') {
                        arrAfterDueDateValues.push(reminder.day_number);
                    } else if (reminder.day_after_before === '3') {
                        form.setFieldValue('on_duedate', true);
                    }
                }

                form.setFieldValue('before_duedate', arrBeforeDueDateValues);
                form.setFieldValue('after_duedate', arrAfterDueDateValues);
            }
        });
    };

    const _setFieldValues = () => {
        const dueDateMoment = task.duedate ? moment.utc(task.duedate) : '';

        form.resetFields();

        form.setFieldsValue({
            ...task,
            duedate: dueDateMoment && dueDateMoment.isValid() ? dueDateMoment : null,
            prio: task.prio,
        });
    };

    const _fetchTaskListMenu = () => {
        getTransactionTaskLists(contractID, { pageSize: 50 }).then(resp => {
            if (resp.data) {
                setTaskListMenuInModal(resp.data);
            }
        });
    };

    useEffect(() => {
        _setFieldValues();

        if (!taskListMenu || taskListMenu?.length === 0) {
            _fetchTaskListMenu();
        }

        if (task.id) {
            _getDetail(task.id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form layout="vertical" form={form} requiredMark={false}>
            <Row gutter={[16, 0]}>
                {contractAddress ? (
                    <Col span={24}>
                        <Form.Item label="Transaction Address">{contractAddress || '-'}</Form.Item>
                    </Col>
                ) : (
                    <></>
                )}
                <Col span={24}>
                    <Form.Item
                        label="List"
                        name="list_id"
                        required
                        rules={[{ required: true, message: "List can't be empty" }]}
                    >
                        <Select
                            placeholder="Select the tasklist"
                            options={taskListMenuInModal}
                            defaultActiveFirstOption
                            fieldNames={{
                                label: 'name',
                                value: 'id',
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Task Title"
                        name="title"
                        rules={[{ required: true, message: "Title can't be empty" }]}
                    >
                        <Input placeholder="Enter the title" />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="Assign To" name="assignee">
                        <Select
                            style={{ width: '100%' }}
                            options={subUsers?.map(user => ({
                                label: user.fullname,
                                value: user.account_id,
                            }))}
                            showArrow
                            showSearch
                            placeholder="Choose team member"
                            optionFilterProp="label"
                        />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="Due Date" name="duedate">
                        <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="Priority" name="prio" initialValue={0}>
                        <Select
                            placeholder="Select the priority"
                            options={[
                                {
                                    label: (
                                        <Badge
                                            count={'-1'}
                                            style={{ backgroundColor: '#2db7f5' }}
                                        />
                                    ),
                                    value: -1,
                                },
                                {
                                    label: (
                                        <Badge count={'±0'} style={{ backgroundColor: 'grey' }} />
                                    ),
                                    value: 0,
                                },
                                {
                                    label: (
                                        <Badge count={'+1'} style={{ backgroundColor: 'gold' }} />
                                    ),
                                    value: 1,
                                },
                                {
                                    label: (
                                        <Badge count={'+2'} style={{ backgroundColor: '#f50' }} />
                                    ),
                                    value: 2,
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item label="Notes" name="note">
                        <Input.TextArea placeholder="Enter the notes" rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <Divider orientation="left">
                <b>
                    <BellFilled /> Reminder for due date
                </b>
            </Divider>
            <Row gutter={[16, 0]}>
                <Col span={10}>
                    <Form.Item label="No. of Days Before Due Date" name="before_duedate">
                        <Select
                            placeholder="Select no. of days"
                            mode="multiple"
                            allowClear
                            options={[...NO_OF_DAYS_REMINDER].reverse().map(val => ({
                                label: val + 1,
                                key: val + 1,
                                value: (val + 1).toString(),
                            }))}
                        />
                    </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                    <Form.Item
                        label="On Due Date"
                        name="on_duedate"
                        valuePropName="checked"
                        style={{ textAlign: 'center', width: '100%' }}
                    >
                        <Checkbox style={{ lineHeight: '32px' }}></Checkbox>
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="No. of Days After Due Date" name="after_duedate">
                        <Select
                            placeholder="Select no. of days"
                            mode="multiple"
                            allowClear
                            options={NO_OF_DAYS_REMINDER.map(val => ({
                                label: val + 1,
                                key: val + 1,
                                value: (val + 1).toString(),
                            }))}
                        />
                    </Form.Item>
                </Col>
                {
                    // TODO: implement sending the reminders to external parties, i.e.: sellers, buyers, and agents
                }
                {/* <Col span={24}>
                    <Form.Item label="Send Reminder To" name="send_reminder_to">
                        <Select
                            placeholder="Select the party(es)"
                            mode="multiple"
                            allowClear
                            options={optGroups}
                        />
                    </Form.Item>
                </Col> */}
            </Row>
        </Form>
    );
};
