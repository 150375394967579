import React, { useMemo, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import { Spin, Select } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { AxiosResponse } from 'axios';

import {
    ContractBuyer,
    ContractBuyerAgents,
    ContractLandlords,
    ContractLeasingAgent,
    ContractReferralSources,
    ContractSellerAgents,
    ContractSellers,
    ContractTenants,
    PARTY_TYPES,
} from 'types/transaction';
import { getTransactionParties } from 'utils/ez_api/transactionAPIs';

export interface EZSelectPartyProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions?: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
    placeholderOnFocus?: JSX.Element;
    defaultPlaceholder?: JSX.Element;

    partyType?: PARTY_TYPES;
}

async function _fetchParties(partyType: PARTY_TYPES, searchKeyword: string): Promise<any[]> {
    return getTransactionParties(partyType, searchKeyword).then((response: AxiosResponse<any>) => {
        switch (partyType) {
            case 'contract_buyers':
                return response.data?.map((record: ContractBuyer) => ({
                    label: (
                        <span>
                            {record.buyer_name} {record.email && `(${record.email})`}
                        </span>
                    ),
                    selectedLabel: `${record.buyer_name}`,
                    value: record.buyer_name,
                }));

            case 'contract_buyer_agents':
                return response.data?.map((record: ContractBuyerAgents) => ({
                    label: (
                        <span>
                            {record.agent_name} {record.email && `(${record.email})`}
                        </span>
                    ),
                    selectedLabel: `${record.agent_name}`,
                    value: record.agent_name,
                }));

            case 'contract_sellers':
                return response.data?.map((record: ContractSellers) => ({
                    label: (
                        <span>
                            {record.seller_name} {record.email && `(${record.email})`}
                        </span>
                    ),
                    selectedLabel: `${record.seller_name}`,
                    value: record.seller_name,
                }));

            case 'contract_seller_agents':
                return response.data?.map((record: ContractSellerAgents) => ({
                    label: (
                        <span>
                            {record.broker_name} {record.email && `(${record.email})`}
                        </span>
                    ),
                    selectedLabel: `${record.broker_name}`,
                    value: record.broker_name,
                }));

            case 'contract_tenants':
                return response.data?.map((record: ContractTenants) => ({
                    label: (
                        <span>
                            {record.tenant_name} {record.email && `(${record.email})`}
                        </span>
                    ),
                    selectedLabel: `${record.tenant_name}`,
                    value: record.tenant_name,
                }));

            case 'contract_landlords':
                return response.data?.map((record: ContractLandlords) => ({
                    label: (
                        <span>
                            {record.landlord_name} {record.email && `(${record.email})`}
                        </span>
                    ),
                    selectedLabel: `${record.landlord_name}`,
                    value: record.landlord_name,
                }));

            case 'contract_referral_sources':
                return response.data?.map((record: ContractReferralSources) => ({
                    label: (
                        <span>
                            {record.referral_source_name} {record.email && `(${record.email})`}
                        </span>
                    ),
                    selectedLabel: `${record.referral_source_name}`,
                    value: record.referral_source_name,
                }));
            case 'contract_leasing_agents':
                return response.data?.map((record: ContractLeasingAgent) => ({
                    label: (
                        <span>
                            {record.leasing_agent_name} {record.email && `(${record.email})`}
                        </span>
                    ),
                    selectedLabel: `${record.leasing_agent_name}`,
                    value: record.leasing_agent_name,
                }));

            default:
                return [];
        }
    });
}

export function EZSelectParty<
    ValueType extends {
        key?: string;
        label: React.ReactNode;
        selectedLabel?: React.ReactNode;
        value: string | number;
    } = any
>({
    fetchOptions,
    debounceTimeout = 800,
    placeholderOnFocus,
    defaultPlaceholder,
    partyType,

    ...props
}: EZSelectPartyProps<ValueType>) {
    const [fetching, setFetching] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [onFocus, setOnFocus] = useState(false);
    const [options, setOptions] = useState<ValueType[]>([]);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (searchKeyword: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            setKeyword(searchKeyword);

            if (partyType) {
                _fetchParties(partyType, searchKeyword).then(newOptions => {
                    if (fetchId !== fetchRef.current) {
                        // for fetch callback order
                        return;
                    }

                    setOptions(newOptions);
                    setFetching(false);
                });
            } else {
                fetchOptions?.(searchKeyword).then(newOptions => {
                    if (fetchId !== fetchRef.current) {
                        // for fetch callback order
                        return;
                    }

                    setOptions(newOptions);
                    setFetching(false);
                });
            }
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={
                fetching ? (
                    <Spin size="small" />
                ) : keyword ? (
                    'No result'
                ) : (
                    'Type a keyword to search'
                )
            }
            placeholder={onFocus ? placeholderOnFocus : defaultPlaceholder}
            onFocus={() => setOnFocus(true)}
            onBlur={() => setOnFocus(false)}
            options={options}
            {...props}
        />
    );
}
