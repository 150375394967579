// Core React libraries import
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

// Core ANTD libraries import
import { Row, Col } from 'antd';

// EZ web-app utils import
import { loginInterface, portToString } from '../utils/ez_api/loginUtil';
import { isDemoENV } from 'utils/envUtil';
// EZ web-app components import
import { IResolveParams } from '../components/social';
import { LogoImg } from '../components/social/LogoImg';
import { DemoBanner } from 'components/general/demoBanner';
// EZ web-app pages import
import { LoginUser } from './subpages/LoginUser';
// EZ web-app styles import
import '../styles/login.scss';
import { showNotification } from 'utils/notificationUtil';

export interface LoginProps {
    handleResolve: any;
    handleReject: any;
    userLoggedIn: any;
    showNotification?: any;
}

export const Login = React.memo(function Login(props: any) {
    // const [isLoading, setIsLoading] = React.useState(false);
    // const [isRegisterOpen, setRegisterOpen] = React.useState(false);
    const history = useHistory();
    // const [notificationSuccess, setNotificationSuccess] = useState<string | null>(null);

    useEffect(() => {
        // const isNotification = getNotification();
        // setNotificationSuccess(isNotification);
        // setTimeout(() => setNotificationSuccess(null), 3000);
        if (localStorage.getItem('jwt')) {
            history.push('/home');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoginResolve = (res: IResolveParams) => {
        // setIsLoading(true);
        localStorage.setItem('loginType', portToString(res.provider));
        localStorage.setItem('loginToken', res?.data?.access_token);
        loginInterface(res.provider, res?.data?.access_token).then(res => {
            localStorage.setItem('jwt', res.data.jwt);
            history.push('/home');
        });
    };

    const handleReject = (err: any) => {
        console.log('🚀 ~ file: Login.tsx:56 ~ handleReject ~ err', err);
    };

    // const handleSignUp = () => {
    //     history.push('/verify-user');
    // };

    const handleLogin = () => {
        history.push('/home');
    };

    // if (isLoading) return <Spin />;

    return (
        <Row justify="center">
            <Col
                xs={{ offset: 0, span: 16 }}
                sm={{ offset: 0, span: 16 }}
                md={{ offset: 0, span: 12 }}
                lg={{ offset: 0, span: 12 }}
                xl={{ offset: 0, span: 8 }}
            >
                <div className="login-wrapper">
                    <div className="login-page">
                        <LogoImg />
                        <div className="login-register-wrapper">
                            <LoginUser
                                handleResolve={handleLoginResolve}
                                handleReject={handleReject}
                                userLoggedIn={handleLogin}
                            />
                            <div className="new-registration mt-4 pt-4">
                                {!isDemoENV() && (
                                    <span>
                                        Not registered yet?{' '}
                                        <Link to="/register">
                                            <span className="">Register</span>
                                        </Link>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col>{isDemoENV() && <DemoBanner />}</Col>
        </Row>
    );
});
