import { memo, useState } from 'react';

import { Alert, Button } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoginFacebook, LoginGoogle, LoginMicrosoft } from '../../components/social';
import { facebookID, GOOGLE_CLIENTID, microsoftID, REDIRECT_URI } from '../../utils/envUtil';
import { googleID } from '../../utils/envUtil';
import { LoginEmail } from '../../components/social';
import { LoginProps } from '../Login';

import '../../styles/login.scss';

import google from '../../assets/google.png';
import microsoft from '../../assets/microsoft.png';
import facebook from '../../assets/facebook.png';

export const LoginUser = memo(function Login(props: LoginProps) {
    const { handleReject, handleResolve, userLoggedIn } = props;

    const [isError, setIsError] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorDescription, setErrorDescription] = useState('');

    const _setError = (_isError: boolean, _errorTitle?: string, _errorDescription?: string) => {
        setIsError(_isError);
        setErrorTitle(_errorTitle || '');
        setErrorDescription(_errorDescription || '');
    };

    return (
        <div>
            <div className="login-form">
                <div className="login-title"> Log in to your account </div>
                {isError && (
                    <Alert
                        message={errorTitle || 'Error'}
                        description={errorDescription || '-'}
                        type="error"
                        showIcon
                        closable
                        className="mb-5"
                    />
                )}
                <LoginEmail userLoggedIn={userLoggedIn} setError={_setError} />
                <div className="login-with-seperator">
                    <span className="seperator"></span>
                    <span className="login-with">OR</span>
                    <span className="seperator"></span>
                </div>
                <div className="login-integration">
                    <GoogleOAuthProvider clientId={GOOGLE_CLIENTID}>
                        <LoginGoogle onResolve={handleResolve} onReject={handleReject}>
                            <img className="login-icon" src={google} alt="signin" />
                            <span className="login-int-text">Login with Google</span>
                        </LoginGoogle>
                    </GoogleOAuthProvider>
                    <div className="mt-1"></div>
                    <LoginMicrosoft
                        className="login-int-button"
                        onResolve={handleResolve}
                        onReject={handleReject}
                    >
                        <img className="login-icon" src={microsoft} alt="signin" />
                        <span className="login-int-text">Login with Microsoft</span>
                    </LoginMicrosoft>
                    {/* <LoginFacebook
                        className="login-int-button"
                        appId={facebookID}
                        redirect_uri={REDIRECT_URI}
                        onResolve={handleResolve}
                        onReject={handleReject}
                    >
                        <img className="login-icon" src={facebook} alt="signin" />
                        <span className="login-int-text">Login with Facebook</span>
                    </LoginFacebook> */}
                </div>
            </div>
        </div>
    );
});
