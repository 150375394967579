/**
 *
 * LoginSocialGoogle
 *
 */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { objectType, IResolveParams } from './';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { useHistory } from 'react-router-dom';

import { showMessage, showNotification } from 'utils/notificationUtil';
import { REDIRECT_URI, GOOGLE_CLIENTID } from 'utils/envUtil';
import { exchangeGoogleOauthCodeForLogin, storeLoginPayload } from 'utils/ez_api/loginUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

interface Props {
    children?: React.ReactNode;
    onReject: (reject: string | objectType) => void;
    onResolve: ({ provider, data }: IResolveParams) => void;
}

const LoginSocialGoogle = memo(({ onReject, onResolve, children }: Props) => {
    const history = useHistory(); // To programmatically navigate

    const _loginWithGoogle = useGoogleLogin({
        onSuccess: tokenResponse => {
            const code = tokenResponse.code;
            const scope = tokenResponse.scope;

            console.log('🚀 ~ LoginSocialGoogle ~ code:', code);

            if (!scope.includes('profile')) {
                showNotification(
                    'error',
                    'Required scope is still missing. Please re-authorize your google permission and make sure that you check all the necessary access.'
                );
            } else {
                exchangeGoogleOauthCodeForLogin(code)
                    .then(({ data }) => {
                        storeLoginPayload(data.jwt, data.loginPayload);

                        try {
                            Mixpanel.setLoggedUserData(data.loginPayload);
                        } catch (error) {}

                        showNotification('success', 'Login is successful.');

                        setImmediate(() => {
                            history.push('/home');
                        });
                    })
                    .catch(err => {
                        showMessage('error', 'User not found. Please use a correct email address.');
                    });
            }
        },
        flow: 'auth-code',
        scope: 'openid profile email',
        redirect_uri: REDIRECT_URI,
    });

    //  const handlelogin = () => {
    //      console.log("handle login")
    //  }

    return (
        //      <div>
        //          <div id="g_id_onload"
        //      data-client_id={client_id}
        //      data-callback={handlelogin}>
        // </div>

        <div className="login-int-button" onClick={_loginWithGoogle}>
            {children}
        </div>
        //  </div>
    );
});

export default LoginSocialGoogle;
