import axios from 'axios';
import { getData } from '../apiUtil';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

let notificationMessage: string | null = null;

export enum InterfacePort {
    'google',
    'microsoft',
    'facebook',
}

interface JWTData {
    jwt: string;
}
export interface LoginResponse {
    status: number;
    success: boolean;
    message: string;
    data: JWTData;
    errors: string[];
}

export const loginUser = (email: string, password: string, remember: boolean) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + 'login',
        auth: getAuth(),
        data: {
            email,
            password,
            remember,
        },
    });
};

export const loginInterface = (port: InterfacePort, token: string) => {
    return axios({
        method: 'post',
        url: `${apiUrl}login/${portToString(port)}`,
        auth: getAuth(),
        data: {
            access_token: token,
        },
    });
};

export const getUserInfo = () => {
    return getData('me');
    //  axios({
    //     method: 'get',
    //     url: `${apiUrl}me`,
    //     headers: {
    //         Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    //     },
    // });
};

export const getAllRoles = () => {
    return axios({
        method: 'get',
        url: `${apiUrl}roles`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
    });
};

export const addRole = (role: string) => {
    return axios({
        method: 'post',
        url: `${apiUrl}me/assign-roles`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
        data: {
            role_id: 4,
            role_name: role,
        },
    });
};

export const signUpUser = (fullname: string, email: string, password: string) => {
    return axios({
        method: 'post',
        url: apiUrl + 'signup',
        auth: getAuth(),
        data: {
            fullname,
            email,
            password,
        },
    });
};

export const signupInterface = (port: InterfacePort, token: string) => {
    return axios({
        method: 'post',
        url: `${apiUrl}signup/${portToString(port)}`,
        auth: getAuth(),
        data: {
            access_token: token,
        },
    });
};

export const verifyEmail = (code: number) => {
    return axios({
        method: 'post',
        url: `${apiUrl}verify-email/`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
        data: {
            code,
        },
    });
};

export const resendVerifyEmail = () => {
    return axios({
        method: 'post',
        url: `${apiUrl}verify-email/`,
        auth: getAuth(),
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
    });
};

export const forgotPassword = (email: string) => {
    return APIRequest({
        method: 'post',
        url: `${apiUrl}forgot-password/`,
        auth: getAuth(),
        data: {
            email,
        },
    });
};

export const resetPassword = (code: string, password: string) => {
    Mixpanel.track('auth.reset_password', {});

    return axios({
        method: 'post',
        url: `${apiUrl}reset-password/`,
        auth: getAuth(),
        data: {
            code,
            password,
        },
    });
};

export const verifyResetPasswordCode = (code: string) => {
    return APIRequest({
        method: 'post',
        url: `${apiUrl}verify-reset-password-code/`,
        auth: getAuth(),
        data: {
            code,
        },
    });
};

export const updatePassword = (formData: any) => {
    return axios({
        method: 'put',
        url: `${apiUrl}me/update-password/`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
        data: formData,
    });
};

const getAuth = () => {
    return {
        username: 'Ezcoordinator-Backend',
        password: '$2b$10$4MqmIdh9kwWJZP87AsmfUOBJXOYA7XBEPeD1S9yo1inw2DQTIjqB6',
    };
};

export const portToString = (port: InterfacePort) => {
    switch (port) {
        case InterfacePort.google:
            return 'google';
        case InterfacePort.facebook:
            return 'facebook';
        case InterfacePort.microsoft:
            return 'microsoft';
    }
};

export const getNotification = () => {
    setTimeout(() => (notificationMessage = null));
    return notificationMessage;
};

export const setNotification = (msg: string) => {
    notificationMessage = msg;
};

export const storeLoginPayload = (jwt: string, loginPayload: any) => {
    localStorage.setItem('jwt', jwt);

    if (loginPayload && loginPayload.account_id) {
        localStorage.setItem('account_id', loginPayload.account_id);
        localStorage.setItem('email', loginPayload.email);
        localStorage.setItem('parent_account_id', loginPayload.parent_account_id);
        localStorage.setItem('account_type', loginPayload.account_type);
        localStorage.setItem('user_type_id', loginPayload.user_type_id);
        localStorage.setItem('status', loginPayload.status);
        localStorage.setItem('date_posted', loginPayload.date_posted);
    }
};

export const clearLocalStoragePayload = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('account_id');
    localStorage.removeItem('email');
    localStorage.removeItem('parent_account_id');
    localStorage.removeItem('account_type');
    localStorage.removeItem('user_type_id');
    localStorage.removeItem('status');
    localStorage.removeItem('date_posted');
    localStorage.removeItem('onboarding_list');

    localStorage.removeItem('loginType');
    localStorage.removeItem('loginToken');
};

export const fetchFileNames = (searchKey: string) => {
    return axios({
        method: 'get',
        url: `${apiUrl}filename?search=${searchKey}`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
    });
};

export const fetchAllTeamMembers = () => {
    return axios({
        method: 'get',
        url: `${apiUrl}members`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
    });
};

export const addTeamMember = (data: any) => {
    return axios({
        method: 'post',
        url: `${apiUrl}member/add`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
        data,
    });
};

export const updateTeamMember = (data: any) => {
    return axios({
        method: 'put',
        url: `${apiUrl}member/add`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
        data,
    });
};

export const deleteTeamMember = (id: number) => {
    return axios({
        method: 'delete',
        url: `${apiUrl}member/${id}`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
    });
};

export const updateUserProfile = (data: any) => {
    return axios({
        method: 'put',
        url: `${apiUrl}me/update-profile`,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
        data,
    });
};

export const registerNewAccount = (payload: any) => {
    return axios({
        method: 'post',
        url: apiUrl + `signup`,
        auth: getAuth(),
        data: payload,
    }).then(resp => {
        Mixpanel.track('app.register_new_account', {
            email: payload.email,
            user_type_id: payload.user_type_id,
            promo_code: payload.promo_code,
            organization_size: payload.organization_size,
            posted_date: new Date(),
        });

        return resp;
    });
};

export const checkAccountEmail = (email: string) => {
    return axios({
        method: 'post',
        url: apiUrl + `check-account-email`,
        auth: getAuth(),
        data: { email },
    });
};

export const resendVerificationEmail = (email: string) => {
    return axios({
        method: 'post',
        url: apiUrl + `resend-verify-email`,
        auth: getAuth(),
        data: { email },
    });
};

export const verifyConfirmationCode = (email: string, code: string) => {
    return axios({
        method: 'post',
        url: apiUrl + `verify-email`,
        auth: getAuth(),
        data: { email, code },
    });
};

export const getMSOauthLoginURL = () => {
    Mixpanel.track('app.login_with_microsoft_is_clicked', {});

    return APIRequest(
        {
            method: 'post',
            url: apiUrl + `ms-oauth/login-url`,
        },
        false
    );
};

export const exchangeMSOauthCodeForLogin = (code: string, state: string) => {
    return APIRequest(
        {
            method: 'post',
            url: apiUrl + `ms-oauth/authorize`,
            data: { code, state },
        },
        false
    );
};

export const exchangeGoogleOauthCodeForLogin = (code: string) => {
    Mixpanel.track('app.swap_google_code_with_token_for_login', {});

    return APIRequest({
        method: 'post',
        url: apiUrl + `google-oauth/authorize`,
        data: { code },
    });
};
