import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { MSloginRequest } from '../envUtil';
import { getMessages, getUserDetails, sendMSGraphEmail } from '../ez_api/graphService';

let instance: IPublicClientApplication, accounts: AccountInfo[];

export const setMSAccountDetails = (
    instanceRef: IPublicClientApplication,
    accountsRef: AccountInfo[]
) => {
    instance = instanceRef;
    accounts = accountsRef;
};

export const RequestAccessToken = async () => {
    const accessToken = localStorage.getItem('loginToken');
    if (accessToken) {
        return { accessToken };
    }
    const request = {
        ...MSloginRequest,
        account: accounts?.[0],
    };

    let response;
    try {
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        response = await instance.acquireTokenSilent(request);
    } catch (e) {
        response = await instance.acquireTokenPopup(request);
    }
    return response;
};

export const getMyProfile = async () => {
    const access = await RequestAccessToken();
    return getUserDetails(access);
};

export const handleMSLogin = async () => {
    return (
        instance
            .loginRedirect(MSloginRequest)
            // .loginPopup(MSloginRequest)
            .then(res => {
                console.log('🚀 ~ handleMSLogin ~ res:', res);
                return {
                    // access_token: res?.accessToken,
                    // access_token: res?.accessToken,
                };
            })
            .catch(e => {
                console.error(e);
            })
    );
};

export const handleMSLogout = () => {
    return instance.logoutPopup().then(res => {
        console.log('logged out', res);
    });
};
