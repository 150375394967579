// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Input, Form, Col, Divider, Button, Popconfirm, Steps, Skeleton, Alert } from 'antd';
import moment from 'moment';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
import { TransactionDocItemType } from 'types/transaction';
import { EZAutocompleteParty } from 'components';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { showMessage } from 'utils/notificationUtil';
import { generateDocuSignEnvelope } from 'utils/ez_api/documentAPIs';
import { DocusignIntegrationIndicator } from 'components/partials/docusignIntegrationIndicator';
// EZ web-app components import

// EZ web-app styles and assets import

type STEPS = 'SET_RECIPIENTS' | 'REQUESTING_ENVELOPE' | 'SENDING_ENVELOPE';

const { Step } = Steps;

const steps = [
    {
        title: 'Add the recipient(s)',
        description: 'Fill out all the fields.',
    },
    {
        title: 'Preparing the envelope',
        description: 'Our system communicates with the DocuSign APIs.',
    },
    {
        title: 'Set up your docs and send',
        description: 'Arrange the signature field.',
    },
];

export const EsignatureModalContent: React.FC<{
    contractID: number;
    doc: TransactionDocItemType;
}> = ({ doc, contractID }) => {
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = useState(0);
    const [viewerURL, setViewerURL] = useState('');

    const _getDetail = () => {};

    const _onSelectName = (_: any, opt: any, index: number) => {
        form.setFieldValue(['recipients', index, 'fullname'], opt.fullname);
        form.setFieldValue(['recipients', index, 'email'], opt.email);
    };

    const _setFieldValues = () => {
        form.resetFields();

        form.setFieldValue('recipients', [{}]);
    };

    const _processForm = () => {
        const recipients: { email?: string; fullname?: string }[] =
            form.getFieldValue('recipients');

        if (!recipients || !recipients.length) {
            return showMessage('error', "Recipient's fields can't be empty");
        }

        for (let index = 0; index < recipients.length; index++) {
            const recipient = recipients[index];

            if (!recipient || !recipient.email || !recipient.fullname) {
                return showMessage('error', "Recipient's fields can't be empty");
            }
        }

        form.validateFields()
            .then(formValues => {
                console.log(
                    '🚀 ~ file: esignatureModal.tsx ~ line 101 ~ .then ~ formValues',
                    formValues
                );
                setCurrentStep(1);
                _generateDocuSignViewerURL(formValues);
            })
            .catch(err => {
                console.log('🚀 ~ file: esignatureModal.tsx ~ line 77 ~ err', err);
                showMessage('error', 'Invalid data is found. Please double-check your form.');
            });
    };

    const _generateDocuSignViewerURL = (formValuesParam: any) => {
        const formValues: {
            emailSubject: string;
            emailBlurb: string;
            recipients: { email: string; fullname: string }[];
        } = formValuesParam;

        generateDocuSignEnvelope(
            doc.contract_id,
            doc.doc_id,
            formValues.emailSubject,
            formValues.emailBlurb,
            formValues.recipients
        )
            .then(resp => {
                if (resp.data?.viewerURL) {
                    setViewerURL(resp.data.viewerURL);
                    setCurrentStep(2);
                }
            })
            .catch(err => {
                console.log(
                    '🚀 ~ file: esignatureModal.tsx ~ line 116 ~ generateDocuSignEnvelope ~ err',
                    err
                );
                showMessage(
                    'error',
                    'Failed to create the DocuSign Envelope. Please try again later.'
                );
                _prevStep(0);
            });
    };

    const _prevStep = (currentStep: number) => {
        switch (currentStep) {
            case 0:
                setCurrentStep(0);
                setViewerURL('');
                break;
            case 1:
                setCurrentStep(currentStep - 1);
                break;
            case 2:
                setCurrentStep(0);
                setViewerURL('');
                break;

            default:
                break;
        }
    };

    const _nextStep = (currentStep: number) => {
        switch (currentStep) {
            case 0:
                _processForm();
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        _setFieldValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form layout="vertical" form={form} requiredMark={false} className="mt-5">
            <DocusignIntegrationIndicator />
            <Steps className="mt-5 mb-5" current={currentStep}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} description={item.description} />
                ))}
            </Steps>
            {currentStep === 0 ? (
                <>
                    <Row gutter={[16, 0]} className="mt-3">
                        <Col span={24}>
                            <Form.Item
                                label="Email Subject"
                                name="emailSubject"
                                initialValue={'Please sign this document'}
                                rules={[{ required: true, message: "Subject can't be empty" }]}
                            >
                                <Input placeholder="Enter the subject" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Email Content" name="emailBlurb">
                                <Input.TextArea rows={4} placeholder="Enter the email content" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">
                        <b>Recipients</b>
                    </Divider>
                    <Row>
                        <Col span={24}>
                            <Form.List name="recipients">
                                {(fields, { add, remove }) => (
                                    <Row gutter={[12, 12]}>
                                        {fields.map(({ key, name, ...restField }, index) => (
                                            <React.Fragment key={`docusign_recipient_${index}`}>
                                                <Col span={10} className="ez-form-label">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'fullname']}
                                                        noStyle
                                                    >
                                                        <EZAutocompleteParty
                                                            placeholder="Enter or search the name"
                                                            style={{ width: '100%' }}
                                                            onSelect={(value: any, option: any) => {
                                                                _onSelectName(value, option, index);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={14}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'email']}
                                                        rules={[{ required: true, type: 'email' }]}
                                                        noStyle
                                                    >
                                                        <Input
                                                            style={{ width: '80%' }}
                                                            placeholder="Email address"
                                                            type="email"
                                                        />
                                                    </Form.Item>

                                                    <Popconfirm
                                                        placement="bottomRight"
                                                        title={`Delete this recipient?`}
                                                        okText="Yes"
                                                        cancelText="Cancel"
                                                        onConfirm={() => remove(name)}
                                                        overlayClassName="custom-contingency-remover-popup"
                                                        // showArrow={false}
                                                    >
                                                        <MinusCircleOutlined className="ml-3" />
                                                    </Popconfirm>
                                                </Col>
                                            </React.Fragment>
                                        ))}
                                        <Col span={24}>
                                            {' '}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    block
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add new recipient
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                </>
            ) : currentStep === 1 ? (
                <Row>
                    <Col span={24}>
                        <Alert
                            message="We are currently processing the selected document/file. And very important that:"
                            description={
                                <>
                                    <div>
                                        <span>- Bigger file requires more time to process</span>
                                        <br />
                                        <span>
                                            - Non-PDF or Docx file also requires longer time to
                                            process and load
                                        </span>
                                    </div>
                                </>
                            }
                            type="info"
                            showIcon
                        />
                        <Skeleton className="mt-4 mb-5" active />
                    </Col>
                </Row>
            ) : currentStep === 2 ? (
                <Row>
                    <Col span={24}>
                        {viewerURL ? (
                            <iframe
                                width={'100%'}
                                height={'700px'}
                                style={{ border: 'none' }}
                                src={viewerURL}
                                title="my-iframe"
                            ></iframe>
                        ) : (
                            ''
                        )}
                    </Col>
                </Row>
            ) : (
                ''
            )}

            <Row gutter={[16, 0]} className="mt-3">
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        type="default"
                        className="mr-3"
                        disabled={
                            currentStep === 0 || currentStep === 1
                            // ||
                            // currentStep === steps.length - 1
                        }
                        onClick={() => _prevStep(currentStep)}
                    >
                        Back
                    </Button>
                    <Button
                        disabled={currentStep === 1 || currentStep === steps.length - 1}
                        type="primary"
                        onClick={() => _nextStep(currentStep)}
                    >
                        Next
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
